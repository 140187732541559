import React from "react";

import { Map, Folder, Layers } from "@mui/icons-material";

function FileItemIcon({ type }) {
    switch (type) {
        case "directory":
            return <Folder />;
        case "workspace":
            return <Map />;
        case "layer":
            return <Layers />;
        default:
            console.error(`Unknown content type "${type}"`);
            return null;
    }
}

export default FileItemIcon;
