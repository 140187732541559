import React, { useState, useContext, useCallback } from "react";
import { addEnvironment, APPS } from "foran-ux/dist/apps";
import { useAsyncEffect } from "./useAsyncEffect";
import { AtlasContext } from "./atlas-context";
import { ErrorContext } from "./error-context";
import Api from "../Api";
import FileListItem from "./FileListItem";
import SyncStatus from "./SyncStatus";

import { Dialog, List, Typography, Menu, MenuItem, DialogActions, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    CloudDownload as CloudDownloadIcon,
    Folder as FolderIcon,
    CloudOff as CloudOffIcon,
    SyncAlt as SyncAltIcon
} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(1)
    },
    bold: {
        fontWeight: "bold"
    },
    list: {
        backgroundColor: "#ffffff"
    },
    menuIcon: {
        marginRight: theme.spacing(1)
    },
    paper: {
        margin: 0,
        width: `calc(100% - ${theme.spacing(2)})`
    },
    syncStatus: {
        padding: theme.spacing(1)
    },
    destructive: {
        color: theme.colors.destructive
    }
}));

function FileList({ disabled, className, files, title, fileItemProps, disableMenu, disableFile, onChange }) {
    const classes = useStyles();

    const drive = addEnvironment(APPS.drive);
    const [driveLink, setDriveLink] = useState(null);

    const { dbManager } = useContext(AtlasContext);
    const { setError } = useContext(ErrorContext);

    const [clickedFile, setClickedFile] = useState(null);
    const [loading, setLoading] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [syncStatusFile, setSyncStatusFile] = useState(null);

    const markOffline = async () => {
        setMenuAnchor(null);
        setLoading(clickedFile._id);
        try {
            await dbManager.addOfflineWorkspace(clickedFile);
            onChange();
        } catch (error) {
            console.logError(error, "Error marking workspace for offline use");
            setError(error);
        }
        setLoading(null);
    };

    const removeOfflineMarking = async () => {
        setMenuAnchor(null);
        setLoading(clickedFile._id);
        await dbManager.removeOfflineWorkspace(clickedFile._id);
        onChange();
        setLoading(null);
    };

    useAsyncEffect(
        useCallback(
            async (isCancelled, signal) => {
                setDriveLink(null);
                if (!clickedFile) {
                    return;
                }
                const directory = await Api.getParentId(clickedFile._id, signal);
                if (isCancelled()) {
                    return;
                }
                setDriveLink(`${drive.url}/directory/${directory}`);
            },
            [clickedFile, drive.url]
        )
    );

    const isMarkedOffline = file => file && file.__localData && file.__localData.offline;
    const canBeMarkedOffline = file => {
        if (file && file.type === "workspace") {
            if ((Array.isArray(file.area) && file.area.length > 0) || (file.area && file.area.type === "Feature")) {
                return !isMarkedOffline(file);
            }
        }
        return false;
    };

    return (
        <div className={className}>
            {title && (
                <Typography className={classes.bold} variant="h6">
                    {title}
                </Typography>
            )}
            {files.length > 0 ? (
                <List className={classes.list} dense>
                    {files
                        .filter(file => !file.deleted)
                        .map(file => (
                            <FileListItem
                                dbManager={dbManager}
                                key={file._id}
                                disabled={disabled || (disableFile && disableFile(file))}
                                {...fileItemProps}
                                file={file}
                                loading={loading === file._id}
                                disableMenu={disableMenu}
                                onMenuClick={(event, file) => {
                                    setClickedFile(file);
                                    setMenuAnchor(event.currentTarget);
                                }}
                            />
                        ))}
                </List>
            ) : (
                <Typography>Empty</Typography>
            )}
            {!disableMenu && (
                <Menu
                    anchorEl={menuAnchor}
                    open={!!menuAnchor}
                    onClose={() => {
                        setClickedFile(null);
                        setMenuAnchor(null);
                    }}
                >
                    {isMarkedOffline(clickedFile) && (
                        <MenuItem
                            onClick={() => {
                                setMenuAnchor(null);
                                setSyncStatusFile(clickedFile);
                            }}
                        >
                            <SyncAltIcon className={classes.menuIcon} />
                            <Typography variant="inherit">View sync status</Typography>
                        </MenuItem>
                    )}
                    {isMarkedOffline(clickedFile) && (
                        <MenuItem onClick={removeOfflineMarking}>
                            <CloudOffIcon className={classes.menuIcon} />
                            <Typography variant="inherit">Remove offline workspace</Typography>
                        </MenuItem>
                    )}
                    {canBeMarkedOffline(clickedFile) && (
                        <MenuItem onClick={markOffline}>
                            <CloudDownloadIcon className={classes.menuIcon} />
                            <Typography variant="inherit">Download for offline use</Typography>
                        </MenuItem>
                    )}
                    <MenuItem href={driveLink} component="a">
                        <FolderIcon className={classes.menuIcon} />
                        <Typography variant="inherit">View in Drive</Typography>
                    </MenuItem>
                </Menu>
            )}
            <Dialog
                classes={{ paper: classes.paper }}
                maxWidth="xs"
                fullWidth
                open={!!syncStatusFile}
                onClose={() => setSyncStatusFile(null)}
            >
                <SyncStatus className={classes.syncStatus} workspace={syncStatusFile} />
                <DialogActions>
                    <Button color="primary" variant="contained" onClick={() => setSyncStatusFile(null)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FileList;
